@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

:root  {
    --primary-dark: #5651e5;
    --primary-light: #709dff;
}

@layer base {
    html {
        @apply scroll-smooth
    }

body {
    @apply font-[Bebas+Neue]   
}

button {
    @apply px-2 py-2  bg-black  text-[#eec613] 
}

h2 {
    @apply text-xl font-bold
}



}

